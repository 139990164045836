.wrapper {
    background: linear-gradient(140deg, #ebeeed 0%, #f8f7f0, 50%, #e7eaec 100%);
    padding: 80px 80px 120px;

    @media screen and (max-width: 600px) {
        padding: 40px;
    }
}

.container {
    max-width: 1320px;
    margin: 0 auto;

    h2 {
        font-size: 38px;
        margin-top: 0;
    }
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 80px;
    margin-top: 40px;
    margin-bottom: 40px;

    h3 {
        color: orange;
        font-family: var(--primary-font-family) !important;
        font-weight: 600;
    }

    @media screen and (max-width: 450px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
}
