.wrapper {
    padding: 0 var(--default-desktop-padding) 20px;
    background-color: #fff;

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }
}

.border-wrapper {
    max-width: 760px;
    margin: 0 auto 100px;
    display: flex;
    flex-direction: row;
    padding-top: 80px;
    gap: 30px;
    font-size: 16px;
    border-top: 1px solid var(--color-metal);

    img {
        border-radius: 50%;
    }

    .title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        column-gap: 24px;

        .name {
            margin: 0;
            font-size: 26px;
        }

        .role {
            font-size: 18px;
            font-weight: 200;
        }

        @media screen and (max-width: 600px) {
            display: block;
        }
    }

    .about {
        color: #615d5d !important;

        p {
            margin-top: 12px;
            font-weight: 300;
            font-size: 16px;
        }
    }

    .social-links {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-top: 30px;

        svg {
            font-size: 28px;
        }

        a {
            display: flex;
            flex-direction: row;
            gap: 15px;
            color: var(--link-dark);
            font-size: 16px;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        box-sizing: border-box;
    }
}
