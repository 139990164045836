@import '../Generic.module';

.body {
    margin: -16px 0 32px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;

    ol {
        padding: 0 0 0 22px;
    }

    @media screen and (max-width: 1000px) {
        gap: 24px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-auto-flow: column;
        overflow-x: auto;
        margin: 0 calc(var(--default-desktop-padding) * -1);
        padding: 0 var(--default-desktop-padding);

        // Hide scrollbar
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        @media screen and (max-width: 600px) {
            margin: 0 calc(var(--default-mobile-padding) * -1);
            padding: 0 var(--default-mobile-padding);
        }
    }
}

.card {
    padding: 32px;
    display: flex;
    width: 280px;
    min-height: 280px;
    flex-direction: column;
    color: var(--color-sky-darker);
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 2px 20px rgba(0,0,0,0.05);


    .card-title {
        margin: 0;
        font-size: 24px;
        color: var(--color-sky-darker);
    }

    .card-body {
        font-size: 16px;

        p {
            margin: 0;
            color: var(--color-sky-darker);
            font-weight: 300;
        }

        p:first-of-type {
            margin: 10px 0 0;
        }
    }
}
