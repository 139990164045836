@import '../Page.module';

.content {
    padding: 0 var(--default-desktop-padding);
    margin: 0 auto 80px;

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);

        ul {
            padding-left: 30px;
        }
    }
}

.summary {
    padding: 0 var(--default-desktop-padding);
    margin: 0 auto 40px;
    max-width: var(--slim-max-width);

    p {
        margin: 40px auto 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;
    }

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }
}

.author-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    gap: 30px;
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);

    .author-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .title {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: baseline;
        font-size: 20px;
        column-gap: 24px;

        @media screen and (max-width: 600px) {
            font-size: 16px;
            display: block;
        }
    }

    .name {
        font-weight: 700;
        font-family: Muli, sans-serif !important;
    }

    .role {
        font-weight: 200 !important;

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    .details {
        font-size: 14px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        text-shadow: 0 0 10px rgba(0,0,0,1);

        > * + *:before {
            content: "•";
            margin: 0 15px;

            @media screen and (max-width: 600px) {
                margin: 0;
                content: "";
            }
        }

        @media screen and (max-width: 600px) {
            font-size: 12px;
            flex-direction: column;
        }
    }

    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: #FFF solid 2px;
    }

    @media screen and (max-width: 600px) {
        gap: 40px;
        font-size: 16px;
    }
}

.hero {
    position: relative;
    height: 43vh;
    min-height: 420px;
    display: flex;
    background-color: black;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        z-index: 2;
    }

    .image-wrapper {
        img {
            width: 100vw;
            height: 100%;
            object-fit: cover;
            filter: brightness(60%);
        }
    }

    .hero-overlay {
        position: absolute;
        max-width: var(--slim-max-width);
        bottom: 30px;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        padding: 0 var(--default-desktop-padding);
        z-index: 3;

        h1 {
            color: white;
            text-align: left;
            font-size: 46px;
            margin-bottom: 40px;

            @media screen and (max-width: 600px) {
                margin-bottom: 32px;
                font-size: 36px;
            }

            @media screen and (max-width: 420px) {
                margin-bottom: 24px;
                font-size: 30px;
            }
        }

        @media screen and (max-width: 600px) {
            padding: 0 var(--default-mobile-padding);
        }
    }

    @media screen and (max-width: 992px) {
        height: 30vh;
        padding-top: var(--default-mobile-header-height);
    }
}


