@import '../Page.module';

.content {
    h1 {
        text-align: left;
        font-size: 72px;
    }

    @media screen and (max-width: 600px) {
        margin-top: 0;

        ul {
            padding-left: 30px;
        }
    }
}
